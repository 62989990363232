import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/img/icon_download.png'
import _imports_1 from '../../../assets/images/table-empty.png'


const _hoisted_1 = { class: "download-popover-content" }
const _hoisted_2 = { class: "popover-content-title" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "file-name" }
const _hoisted_5 = {
  key: 1,
  class: "empty-wrap"
}

import { ref } from 'vue';
import { postDownloadList, postDownloadUrl } from '@/api/download';
import { downLoad, handleDownLoad } from '@/utils/index';


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props) {

const tableLoading = ref(true); // 请求下载列表状态
const downloadList = ref<Download.DownloadItem[]>([]); // 请求下载列表状态

// 请求下载数据列表
const requestDownloadList = async () => {
  tableLoading.value = true;
  const res = await postDownloadList({});
  const { code, data } = res || {};
  if (`${code}` === '0' && data && data.length !== 0) {
    downloadList.value = data.slice(0, 5);
  }
  tableLoading.value = false;
};
// 跳转到 任务中心-下载列表页面
const goDownloadList = () => {
  window.location.href = `${window.location.origin}/app/task-management/task/downloadList`;
};
// 点击下载文件
const handleDownload = async (item: Download.DownloadItem) => {
  const { tenantId, businessId, businessType } = item || {};
  const res = await postDownloadUrl({
    tenantId,
    businessId,
    businessType
  });
  const { code, data } = res || {};
  if (`${code}` === '0' && data) {
    const { url } = data || {};
    downLoad(url);
  }
};
// popover展示事件
const handlePopoverShow = () => {
  requestDownloadList();
};

return (_ctx: any,_cache: any) => {
  const _component_e_button = _resolveComponent("e-button")!
  const _component_e_icon = _resolveComponent("e-icon")!
  const _component_el_popover = _resolveComponent("el-popover")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createBlock(_component_el_popover, {
    placement: "left",
    title: "",
    width: 276,
    trigger: "click",
    "popper-class": "download-popover",
    onShow: handlePopoverShow
  }, {
    reference: _withCtx(() => _cache[0] || (_cache[0] = [
      _createElementVNode("img", {
        class: "download-icon",
        src: _imports_0,
        alt: ""
      }, null, -1)
    ])),
    default: _withCtx(() => [
      _createElementVNode("ul", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _cache[2] || (_cache[2] = _createElementVNode("span", null, "下载中心", -1)),
          _createVNode(_component_e_button, {
            type: "text",
            size: "mini",
            onClick: goDownloadList
          }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createTextVNode("更多报表")
            ])),
            _: 1
          })
        ]),
        _withDirectives((_openBlock(), _createElementBlock("div", null, [
          (downloadList.value.length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(downloadList.value, (item) => {
                  return (_openBlock(), _createElementBlock("li", {
                    key: item.id,
                    class: "download-popover-item"
                  }, [
                    _cache[3] || (_cache[3] = _createElementVNode("span", { class: "dot-icon" }, null, -1)),
                    _createElementVNode("span", _hoisted_4, _toDisplayString(item.fileName), 1),
                    _createVNode(_component_e_icon, {
                      type: "Download",
                      fill: "#666666",
                      class: "item-icon",
                      size: "12px",
                      onClick: ($event: any) => (handleDownload(item))
                    }, null, 8, ["onClick"])
                  ]))
                }), 128))
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_5, _cache[4] || (_cache[4] = [
                _createElementVNode("img", {
                  src: _imports_1,
                  alt: ""
                }, null, -1),
                _createElementVNode("p", null, "暂无数据", -1)
              ])))
        ])), [
          [_directive_loading, tableLoading.value]
        ])
      ])
    ]),
    _: 1
  }))
}
}

})