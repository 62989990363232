import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "aside-wrap" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "store-info-wrap" }
const _hoisted_4 = { class: "store-info" }
const _hoisted_5 = { class: "popover-inner" }
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "menu-text" }

import { onMounted, onUpdated, ref, watch } from 'vue';
import { useRouter, useRoute, onBeforeRouteUpdate } from 'vue-router';
import { getSubAppPath } from '@/micro/apps';
// import shopLogo from '@/assets/img/shopLogo.png';
import logo from '@/assets/img/logo.png';
import { useUserStore } from '@/store/index';
import actions from '@/shared/actions';
import api from '@/api/request';


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {
    menus: {}
  },
  emits: ['go-subApp'],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const userStore = useUserStore();

const router = useRouter();
const route = useRoute();

const user = ref<any>({});

const activePath = ref<string>('/commodity-management');

watch(
  () => route.path,
  () => {
    const currentActive = route.path.split('/');
    // eslint-disable-next-line prefer-destructuring
    activePath.value = currentActive[2];
    console.log('route.path:', route.path);
  }
);

watch(
  () => userStore.userInfo,
  () => {
    user.value = userStore.userInfo;
    actions.setGlobalState({ userInfo: userStore.userInfo });
  },
  { deep: true, immediate: true }
);

const emits = __emit;

const searchShopInput = ref<string>('');

// 采取 popover 嵌套实现
// 外层 popover 展示需要同步增加蒙层监听全局点击控制显示隐藏
const showModal = ref<boolean>(false);
const popoverWrapVisible = ref<boolean>(false);

const logOut = () => {
  // fetch('https://testapi.nr.lingdong.cn/sso/sign/logout', {
  //   credentials: 'include'
  // });
  api.get('/sso/sign/logout', {}, {}, 'logoutBase');
  // axios({
  //   method: 'get',
  //   url: 'https://testapi.nr.lingdong.cn/sso/sign/logout',
  //   withCredentials: true
  // });
  router.push({
    path: '/login'
  });
};
const hideMask = () => {
  showModal.value = false;
  popoverWrapVisible.value = false;
};

const showPopoverWrap = () => {
  showModal.value = true;
  popoverWrapVisible.value = true;
};

// 路由跳转
const gotoSubApp = (resourceTag: string, projectId: number) => {
  const activeRule = getSubAppPath(resourceTag);
  console.log(projectId, '主应用id');
  actions.setGlobalState({ projectId });
  router.push(`${activeRule}`);
  emits('go-subApp');
};

return (_ctx: any,_cache: any) => {
  const _component_e_typography = _resolveComponent("e-typography")!
  const _component_e_icon = _resolveComponent("e-icon")!
  const _component_el_popover = _resolveComponent("el-popover")!
  const _component_el_menu_item = _resolveComponent("el-menu-item")!
  const _component_el_menu = _resolveComponent("el-menu")!
  const _component_el_scrollbar = _resolveComponent("el-scrollbar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (showModal.value)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "mask",
          onClick: hideMask
        }))
      : _createCommentVNode("", true),
    _cache[1] || (_cache[1] = _createElementVNode("div", { class: "icon-wrap" }, null, -1)),
    _createElementVNode("img", {
      src: _unref(logo),
      alt: "",
      class: "store-logo"
    }, null, 8, _hoisted_2),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_el_popover, {
        placement: "bottom",
        width: 128,
        visible: popoverWrapVisible.value
      }, {
        reference: _withCtx(() => [
          _createElementVNode("div", {
            class: "popover-inner",
            onClick: showPopoverWrap
          }, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_e_typography, {
                heading: "4",
                class: "white-color"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(user.value?.relatedDTOList ? user.value.relatedDTOList[0].storeName : ''), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_e_icon, {
                type: "down",
                fill: "#fff"
              })
            ]),
            _createVNode(_component_e_typography, {
              heading: "4",
              class: "white-color"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(user.value?.userName), 1)
              ]),
              _: 1
            })
          ])
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_e_typography, {
              heading: "4",
              class: "pointer",
              onClick: logOut
            }, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [
                _createTextVNode("退出")
              ])),
              _: 1
            })
          ])
        ]),
        _: 1
      }, 8, ["visible"])
    ]),
    _createVNode(_component_el_scrollbar, { class: "menu-wrap" }, {
      default: _withCtx(() => [
        _createVNode(_component_el_menu, {
          "active-text-color": "#000",
          "background-color": "#1F2848",
          "default-active": activePath.value,
          "text-color": "#fff"
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menus, (menu) => {
              return (_openBlock(), _createBlock(_component_el_menu_item, {
                key: menu.projectId,
                index: menu.projectTag,
                onClick: ($event: any) => (gotoSubApp(menu.projectTag, menu.projectId))
              }, {
                title: _withCtx(() => [
                  _createElementVNode("img", {
                    class: _normalizeClass(['menu-icon', menu.projectTag === activePath.value ? '' : 'gray-icon']),
                    src: menu.icon
                  }, null, 10, _hoisted_6),
                  _createElementVNode("span", _hoisted_7, _toDisplayString(menu.name), 1)
                ]),
                _: 2
              }, 1032, ["index", "onClick"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["default-active"])
      ]),
      _: 1
    })
  ]))
}
}

})