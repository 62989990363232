import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "" }

import { useRoute } from 'vue-router';


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props) {

const route = useRoute();

const redirectUrl = encodeURI((route.query.redirectUrl as string) ?? window.location.origin);

if (process.env.VUE_APP_ENV !== 'development') {
  window.open(
    `${process.env.VUE_APP_LOGOUT_HOST}sso/sign/login?tenant=lingdong&side=employee&redirect_url=${redirectUrl}&app_id=cli_a5c6ed9bbfe1100c`,
    '_self'
  );
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1))
}
}

})