import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "image-page" }
const _hoisted_2 = { class: "image-slot" }

import { ref, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { EMessage } from '@aciga/e-ui-next';
import { postDownloadUrl } from '@/api/download';
import { useUserStore } from '@/store/index';


export default /*@__PURE__*/_defineComponent({
  __name: 'privateImageLooker',
  setup(__props) {

const userStore = useUserStore();
const router = useRouter();
const route = useRoute();

const pic = ref<string>();

onMounted(() => {
  // 已登录
  if (userStore.userInfo && userStore.userInfo.userName) {
    getImgUrl();
  }
});

const getImgUrl = async () => {
  const { tenantId } = userStore.userInfo || {};
  const { businessId, businessType } = route.query;
  if (tenantId && businessId && businessType) {
    // @ts-ignore
    const res = await postDownloadUrl({
      tenantId,
      businessId: `${businessId}`,
      businessType: `${businessType}`
    });
    const { code, data } = res || {};
    if (`${code}` === '0' && data) {
      const { url } = data || {};
      pic.value = url;
    }
  } else {
    EMessage.error('参数丢失:');
  }
};

return (_ctx: any,_cache: any) => {
  const _component_icon_picture = _resolveComponent("icon-picture")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_image = _resolveComponent("el-image")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_image, {
      class: "img",
      style: {"width":"80vw","height":"80vh"},
      src: pic.value,
      fit: "contain",
      "preview-src-list": [pic.value],
      "initial-index": 0
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_el_icon, null, {
            default: _withCtx(() => [
              _createVNode(_component_icon_picture)
            ]),
            _: 1
          })
        ])
      ]),
      _: 1
    }, 8, ["src", "preview-src-list"])
  ]))
}
}

})