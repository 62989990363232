import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "" }

import { onMounted } from 'vue';
import { useRouter } from 'vue-router';

import { loginBUserDetail } from '@/api/user';

import { useUserStore } from '@/store/index';


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props) {

const userInfo = useUserStore();

const router = useRouter();

// console.log(1234);
loginBUserDetail()
  .then((res) => {
    userInfo.setUserInfo(res.data.userInfoDTO);
    router.push({
      path: '/'
    });
  })
  .catch(() => {
    router.push({
      path: '/login'
    });
  });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, "123"))
}
}

})