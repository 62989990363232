import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "header" }

import logo from '@/assets/img/logo.png';

export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props) {

// @ts-ignore

return (_ctx: any,_cache: any) => {
  const _component_el_avatar = _resolveComponent("el-avatar")!
  const _component_e_typography = _resolveComponent("e-typography")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_avatar, {
      shape: "square",
      size: 30,
      src: _unref(logo),
      class: "top-avatar"
    }, null, 8, ["src"]),
    _createVNode(_component_e_typography, {
      heading: "1",
      class: "white-color"
    }, {
      default: _withCtx(() => _cache[0] || (_cache[0] = [
        _createTextVNode("新零售工作台")
      ])),
      _: 1
    })
  ]))
}
}

})