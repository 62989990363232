import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "qk-app" }
const _hoisted_2 = {
  key: 0,
  class: "all-wrap"
}
const _hoisted_3 = { class: "frame-wrap" }
const _hoisted_4 = { id: "frame" }

import { onMounted, ref, watch, computed } from 'vue';
import { useRoute } from 'vue-router';

import actions from '@/shared/actions';
import Topbar from '@/components/topbar/index.vue';

import Aside from '@/components/sideBar/index.vue';
import DownloadPopover from '@/components/downloadPopover/index.vue';
import { useSystemStore, useUserStore } from '@/store';

import api from '@/api/request';


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const route = useRoute();
const systemStore = useSystemStore();
const userStore = useUserStore();
const hideMenu = computed(() => systemStore.hideMenu);
const hideTop = computed(() => systemStore.hideTop);

// projects 为主应用左侧展示的菜单栏
const projects = computed(() => systemStore.appMenuList);
const showMain = () => {
  console.log(route.name, 'route.name');
  if (route.name === '404' || route.name === 'privateImage') {
    return false;
  }
  return true;
};

onMounted(() => {
  getTaskManagementResources();
});

const init = () => {
  actions.onGlobalStateChange((state: any): void => {
    // 只能有一个 onGlobalStateChange - 不应该涉及业务
    // systemStore.$patch((pState) => {
    //   pState.hideMenu = state.hideMenu;
    // });
    console.log('状态变了呀。', state);
    if (state.hideMenu !== undefined) {
      console.log('左侧菜单栏状态变更', state.hideMenu);
      systemStore.updateHideMenu(state.hideMenu);
    }
    if (state.hideTop !== undefined) {
      console.log('顶栏状态变更', state.hideTop);
      systemStore.updateHideTop(state.hideTop);
    }
  }, true);
};

init();

const isHasDownloadListAuth = ref<boolean>(false);
// 请求【任务中心】一级菜单权限
// 判断当前用户是否有【任务中心】下【下载列表】权限，用来控制右下角下载icon的显示、隐藏
const getTaskManagementResources = async () => {
  interface AuthResourceItem {
    id: number;
    tenantId: number;
    name: string;
    projectId: number;
    resourceType: number;
    url: string;
    parentId: number;
    icon: string;
    operateType: number;
    status: number;
    createTime: number; // 毫秒时间戳
    createUser: number; // 创建用户uid
    updateTime: number;
    updateUser: string;
    children?: AuthResourceItem[];
  }
  const res = await api.post<{ resources: AuthResourceItem[] }>(
    '/retail/access/control/getUserProjectResource',
    {
      projectId: 47
    },
    {
      type: 'isTenant'
    }
  );
  const { code, data } = res || {};
  if (`${code}` === '0' && data) {
    const { resources } = data || {};
    isHasDownloadListAuth.value = resources.some((item) => item.url === '/downloadList');
  }
};

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (showMain())
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (!hideMenu.value)
            ? (_openBlock(), _createBlock(Aside, {
                key: 0,
                menus: projects.value
              }, null, 8, ["menus"]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_3, [
            (!hideTop.value)
              ? (_openBlock(), _createBlock(Topbar, { key: 0 }))
              : _createCommentVNode("", true),
            _withDirectives(_createElementVNode("div", _hoisted_4, null, 512), [
              [_vShow, !_unref(route).name]
            ]),
            _createVNode(_component_router_view)
          ]),
          (_unref(useSystemStore)().$state.downloadIconShow && isHasDownloadListAuth.value)
            ? (_openBlock(), _createBlock(DownloadPopover, { key: 1 }))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})